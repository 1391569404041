
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import AddCustomer from "@/views/apps/administration/companies/modal/AddCustomer.vue";
import UpdateThreshold from "@/views/apps/administration/companies/modal/UpdateThreshold.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getCustomersListing, deleteCompany } from "@/store/api/devices";
import WasteType from "@/views/apps/administration/companies/modal/WasteType.vue";
import Zone from "@/views/apps/administration/companies/modal/Zone.vue";
import RoutingLocation from "@/views/apps/administration/companies/modal/RoutingLocation.vue";
import AddressDetails from "@/views/apps/administration/companies/modal/AddressDetails.vue";
import { ExportToCsv } from "export-to-csv";

interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    // EditCompanies,
    AddCustomer,
    UpdateThreshold,
    WasteType,
    RoutingLocation,
    Zone,
    AddressDetails
  },
  setup() {
    const customers = ref<any[]>([]);
    const obj = ref<any>();
    const customerData = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const cardData = ref<string>("");
    const searchValue = ref<any>("");
    const headerConfig = ref([
      {
        name: "Id",
        key: "id",
      },
      {
        name: "Name",
        key: "name",
      },
      {
        name: "Company Type",
        key: "customer_type",
      },
      {
        name: "Email",
        key: "email",
      },
      {
        name: "Phone Number",
        key: "phone_number",
      },
      {
        name: "Customer Key",
        key: "token",
      },
      {
        name: "Maximum Users",
        key: "max_users",
      },
      {
        name: "Current Users",
        key: "user_count",
      },
      {
        name: "Time Zone",
        key: "time_zone",
      },
      {
        name: "Action",
        key: "action",
      },
    ]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const permissions = ref<any>("");

    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      loadData();
      setCurrentPageBreadcrumbs("Companies", [""]);
    });

    const loadData = async () => {
      loading.value = true;
      let value = await getCustomersListing(pagination.value);
      customers.value = value.companies;
      customerData.value = customers.value;
      total.value = value.total_number_of_records;
      loading.value = false;
    };
    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const deltCompany = async (id) => {
      Swal.fire({
        title: "Are you sure you want to Delete this Company?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteCompany(id);
          console.log(resp);
          loadData();
          Swal.fire({
            icon: "success",
            title: "Company has been Deleted",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    };
    // const deltCompany = async (id) => {
    //   await deleteCompany(id).then((res) => {
    //     Swal.fire({
    //       text: `${res.data.message}`,
    //       icon: "success",
    //       buttonsStyling: false,
    //       confirmButtonText: "Ok, got it!",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //       },
    //     });
    //     loadData();
    //   });
    // };
    const getCompanyDetail = (val) => {
      obj.value = val;
    };

    const addCustomer = () => {
      obj.value = {
        billing_address: "",
        billing_cycle: "",
        contact_person: "",
        customer_type: "",
        delivery_address: "",
        email: "",
        max_users: "",
        name: "",
        phone_number: "",
        token: "",
        vat_number: "",
        service_id: 0,
        parent_id: 0,
        building: false,
        time_zone: "",
      };
    };

    const setThreshold = (val) => {
      obj.value = val;
    };

    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const getCustomerName = (val) => {
      let name = customers.value.filter((x) => (x.id = val))[0].name;
      return name;
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    const downloadCsv = () => {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "",
        filename: "customers",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(customers.value);
    };

    return {
      headerConfig,
      customers,
      obj,
      total,
      loading,
      deltCompany,
      search,
      pagination,
      cardData,
      currentChange,
      getCompanyDetail,
      searchItems,
      getCustomerName,
      customerData,
      itemsPerPageChange,
      onCustomerChange,
      getCustomersListing,
      searchValue,
      setThreshold,
      downloadCsv,
      addCustomer,
      permissions,
    };
  },
});
