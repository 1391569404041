import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "thresholdmodal",
  ref: "createAPIKeyModalRef",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-lg" }
const _hoisted_3 = {
  class: "modal-content",
  style: {"height":"350px"}
}
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "row justify-content-around" }
const _hoisted_6 = { class: "col-md-5" }
const _hoisted_7 = { class: "fv-plugins-message-container" }
const _hoisted_8 = { class: "fv-help-block" }
const _hoisted_9 = { class: "modal-footer d-flex justify-content-center" }
const _hoisted_10 = {
  ref: "submitButtonRef",
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Modal "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Update Pickup threshold "),
            _createElementVNode("div", {
              class: "btn btn-icon btn-sm ms-5",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ])
          ], -1 /* HOISTED */)),
          _createVNode(_component_Form, {
            class: "form w-100",
            id: "kt_login_signin_form",
            onSubmit: _ctx.submit,
            "validation-schema": _ctx.valid
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createCommentVNode("begin::Label"),
                    _cache[1] || (_cache[1] = _createElementVNode("label", { class: "form-label fs-6 fw-bolder text-dark" }, "Pickup Threshold", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createVNode(_component_Field, {
                      class: "form-control border border-primary form-control-lg form-control-solid",
                      type: "number",
                      name: "threshold",
                      modelValue: _ctx.threshold,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.threshold) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_ErrorMessage, { name: "threshold" })
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", _hoisted_10, " Save ", 512 /* NEED_PATCH */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onSubmit", "validation-schema"])
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}