<template>
    <!-- Modal - Zone -->
    <button
      type="button"
      class="btn btn-primary mx-3"
      data-bs-toggle="modal"
      data-bs-target="#zone_pop_up"
    >
      Zone
    </button>
    <div
      class="modal fade"
      ref="submitButtonRef"
      id="zone_pop_up"
      tabindex="-1"
      aria-hidden="true"
    >
      <!-- Modal dialog -->
      <div class="modal-dialog modal-dialog-centered mw-650px roundeda">
        <!-- Modal content -->
        <div class="modal-content roundeda">
          <!-- Modal header -->
          <div class="modal-header bg-primary">
            <span class="text-white text-center ms-3 fs-5">Zones</span>
            <button
              type="button"
              class="btn btn-primary"
              @click="toggle = !toggle"
              v-if="permissions.includes('add-waste-type')"
            >
              + Zone
            </button>
            <!-- Close Button -->
            <div
              class="display_none btn btn-sm btn-icon btn-active-color-dark bg-white rounded-circle"
              data-bs-dismiss="modal"
              id="addnewmodalclose"
            >
              <span class="svg-icon svg-icon-1 text-primary fs-6"> X </span>
            </div>
          </div>
          <!-- Modal Body -->
          <div class="card-body pt-0 mt-4" style="width: 100%">
  
            <!-- Form to Add Zone (shown when toggle is true) -->
            <div v-if="toggle">
              <b-form @submit="onSubmit" @reset="onReset">
                <b-form-group label="Zone Name" label-for="zone-name">
                  <b-form-input
                    id="zone-name"
                    v-model="payload.name"
                    placeholder="Enter Zone Name"
                    required
                  ></b-form-input>
                </b-form-group>
  
                <b-form-group label="Radius" label-for="zone-radius">
                  <b-form-input
                    id="zone-radius"
                    v-model="payload.radius"
                    type="number"
                    placeholder="Enter Zone Radius"
                    required
                  ></b-form-input>
                </b-form-group>
  
                <b-form-group label="Center Latitude" label-for="center-lat">
                  <b-form-input
                    id="center-lat"
                    v-model="payload.center_lat"
                    type="number"
                    step="any"
                    placeholder="Enter Center Latitude"
                    required
                  ></b-form-input>
                </b-form-group>
  
                <b-form-group label="Center Longitude" label-for="center-long">
                  <b-form-input
                    id="center-long"
                    v-model="payload.center_long"
                    type="number"
                    step="any"
                    placeholder="Enter Center Longitude"
                    required
                  ></b-form-input>
                </b-form-group>
  
                <!-- Submit and Reset buttons -->
                <b-button type="submit" variant="primary">
                  Submit
                  <b-spinner
                    v-if="loading"
                    class="mt-2"
                    small
                    label="Small Spinner"
                  ></b-spinner>
                </b-button>
                <b-button
                  type="reset"
                  style="margin-left: 10px"
                  variant="danger"
                  @click="toggle = !toggle"
                >Reset</b-button>
              </b-form>
            </div>
  
            <!-- Zone List (shown when toggle is false) -->
            <div v-if="!toggle">
              <!-- Company Selection Dropdown -->
              <b-form-select
                v-model="assign_value.company_id"
                class="w-50 float-end mb-4 mt-5"
                @change="fetchZonesOfCompany($event)"
              >
                <b-form-select-option :value="null"
                  >Please Select Company</b-form-select-option
                >
                <option
                  v-for="(option, idx) in customer_list"
                  :key="idx"
                  :value="option.id"
                  :title="option.name"
                >
                  {{ `${option.name}(${option.id})` }}
                </option>
              </b-form-select>
  
              <!-- Zone Table -->
              <b-table
                v-if="zones.length > 0"
                :items="zones"
                :fields="zoneFields"
                class="mt-4"
              ></b-table>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "@vue/runtime-core";
  import {
    addwasteType,
    updatewasteType,
    deletewasteType,
    wasteType_list,
    getCustomersList,
    assignwasteType,
    wasteTypeByCompany,
    zones_list,
    addZone

  } from "@/store/api/devices";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  export default defineComponent({
    name: "editpermission",
    setup() {
      const items = ref([]);
      const permissions = ref([]);
      const zones = ref([]);
      const waste_type_id = ref("");
      const searchValue = ref();
      const loading = ref(false);
      const saveloading = ref(false);
      const toggle = ref(false);
      const customer_list = ref([]);
      const zoneFields = ref([
      { key: "name", label: "Zone Name" },
      { key: "radius", label: "Radius" },
      { key: "center_lat", label: "Center Latitude" },
      { key: "center_long", label: "Center Longitude" },
    ]); // Fields for zone table
      const header = ref([
        { key: "checkbox", label: "" },
        { key: "name", label: "Name" },
        { key: "icon", label: "Icon" },
        { key: "edit", label: "" },
      ]);
      const payload = ref({ name: "", radius: "", center_lat: "", center_long: "" });
      const assign_value = ref({
      company_id: "",
    });
      const deleteIcon = ref(false);
      onMounted(async () => {
        permissions.value = localStorage.getItem("permissions");
        permissions.value = JSON.parse(permissions.value);
        items.value.map((item) => ({ ...item, isEdit: false }));
        const feature = await getCustomersList();
        customer_list.value = feature;
        loadData();
      });
      
      const loadData = async () => {
        const result = await wasteType_list();
        items.value = result.data;
      };
    // Method to handle zone submission
    const onSubmit = async (event) => {
      event.preventDefault();
      if (!assign_value.value.company_id) {
        Swal.fire("Error", "Please select a company first.", "error");
        return;
      }
      loading.value = true;

      // Prepare payload for addZone API
      const zoneData = {
        name: payload.value.name,
        radius: payload.value.radius,
        center_lat: payload.value.center_lat,
        center_long: payload.value.center_long,
        se_account_id: assign_value.value.company_id, // Use selected company ID
      };

      // Call addZone API
      let res = await addZone(zoneData);
      console.log("resresres",res);
      
      if (res && res.id) {
        Swal.fire("Success", "Zone added successfully!", "success");
        toggle.value = false;
        // Reload zones data
        fetchZonesOfCompany(assign_value.value.company_id);
      } else {
        Swal.fire("Error", "Failed to add zone.", "error");
      }
      loading.value = false;
    };
      const fetchZonesOfCompany = async (event) => {
      loading.value = true;
      console.log("eventeventeventevent",event);
      
      const companyId = event;
      if (companyId) {
        const result = await zones_list( companyId );
        zones.value = result.data; // Assuming response contains the list of 
      }
      loading.value = false;
    };
      const editRowHandler = async (data) => {
        items.value[data.index].isEdit = !items.value[data.index].isEdit;
      };
      const updateWasteTypeData = async (data) => {
        console.log("data", data);
        if (items.value[data.index].isEdit === true) {
          waste_type_id.value = data.item.id;
          loading.value = true;
          let payload = {
            name: data.item.name,
            icon: data.item.icon,
          };
          console.log("payload with icon=>", payload);
          const result = await updatewasteType(data.item.id, payload);
          console.log("now you update the value", result);
          loadData();
          loading.value = false;
        }
      };
      const notEdit = (data) => {
        items.value[data.index].isEdit = false;
      };
      const delete_Waste_Type = (data) => {
        console.log(data);
        Swal.fire({
          title: "Are you sure?",
          type: "danger",
          showCancelButton: true,
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Yes, delete it!",
          closeOnConfirm: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            waste_type_id.value = data.item.id;
            loading.value = true;
            const resp = await deletewasteType(data.item.id);
            loadData();
            console.log(resp);
            loading.value = false;
          }
        });
      };
      const waste_types_of_company = async (event) => {
        loading.value = true;
        const resp = await wasteTypeByCompany({ company_id: event });
        assign_value.value.waste_type_ids = resp.data;
        loading.value = false;
      };
  
      const assign_Waste_Type_to_Company = async (val) => {
        let id = val.company_id;
        let res = id.toString();
        saveloading.value = true;
        assign_value.value.company_id = res;
        const result = await assignwasteType(assign_value.value).then(() => {
          document.getElementById("addnewmodalclose").click();
        });
        console.log(result);
        loadData();
        saveloading.value = false;
      };
  
      return {
        header,
        onSubmit,
        editRowHandler,
        updateWasteTypeData,
        notEdit,
        delete_Waste_Type,
        items,
        zoneFields,
        customer_list,
        deleteIcon,
        waste_type_id,
        loading,
        zones,
        saveloading,
        assign_value,
        payload,
        searchValue,
        toggle,
        permissions,
        fetchZonesOfCompany,
        waste_types_of_company,
        assign_Waste_Type_to_Company,
      };
    },
  });
  </script>
  <style scoped>
  .btn.btn-secondary.permission-action {
    padding: 3px !important;
    background: #fff !important;
  }
  .btn.btn-secondary.permission-action:hover {
    background-color: #fff !important;
    background: #fff !important;
  }
  .btn.btn-secondary.permission-action:focus {
    background-color: #fff !important;
    background: #fff !important;
  }
  .badge-light-success.modal-badge {
    padding-top: 4px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
    padding-right: 4px !important;
  }
  /* .table td:last-child {
   display: flex !important;
  } */
  .display_none {
    display: none;
  }
  .display_block {
    display: block;
  }
  span.spinner-border.text-primary {
    z-index: 1;
    position: absolute;
    top: 227px;
    right: 50%;
    justify-content: center;
    display: flex;
  }
  [type="radio"] {
    position: absolute;
    opacity: 0;
  }
  [type="radio"] + img {
    cursor: pointer;
    margin-right: 0.5rem;
  }
  [type="radio"]:checked + img {
    background-color: #0d0c0c;
  }
  .label {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    transition: all 100ms ease-in;
    filter: brightness(0.9) grayscale(1.7) opacity(0.9) !important;
  }
  
  .imgSize {
    margin: 0px;
    width: 37px;
    padding: 0px;
    height: 37px;
  }
  img {
    width: 28px;
    height: 29px;
  }
  .float-end {
    border-color: #009ef7;
  }
  </style>
  