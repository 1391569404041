<template>
    <!-- Modal - Zone -->
    <button
      type="button"
      class="btn btn-primary mx-3"
      data-bs-toggle="modal"
      data-bs-target="#routing_pop_up"
    >
      Routing
    </button>
    <div
      class="modal fade"
      ref="submitButtonRef"
      id="routing_pop_up"
      tabindex="-1"
      aria-hidden="true"
    >
      <!-- Modal dialog -->
      <div class="modal-dialog modal-dialog-centered mw-650px roundeda">
        <!-- Modal content -->
        <div class="modal-content roundeda">
          <!-- Modal header -->
          <div class="modal-header bg-primary">
            <span class="text-white text-center ms-3 fs-5">Routing</span>
          </div>
  
          <!-- Modal Body -->
          <div class="card-body pt-0 mt-4" style="width: 100%">
  
            <!-- Dropdowns for Company and Zone Selection -->
            <div class="d-flex justify-content-between mb-4">
              <!-- Company Selection Dropdown -->
              <b-form-select
                v-model="assign_value.company_id"
                class="w-50"
                @change="fetchZonesOfCompany($event)"
              >
                <b-form-select-option :value="null">Please Select Company</b-form-select-option>
                <option
                  v-for="(option, idx) in customer_list"
                  :key="idx"
                  :value="option.id"
                  :title="option.name"
                >
                  {{ `${option.name}(${option.id})` }}
                </option>
              </b-form-select>
  
              <!-- Zone Selection Dropdown -->
              <b-form-select
                v-model="assign_value.zone_id"
                class="w-50"
                :disabled="!zones.length"
                @change="onZoneSelect"
              >
                <b-form-select-option :value="null">Please Select Zone</b-form-select-option>
                <option
                  v-for="(zone, idx) in zones"
                  :key="idx"
                  :value="zone.id"
                >
                  {{ zone.name }}
                </option>
              </b-form-select>
            </div>
  
            <!-- Lat and Long Input Fields - Display when Zone is selected -->
            <div v-if="assign_value.zone_id">
              <!-- Depo Section -->
              <h5 class="mt-3">Depo</h5>
              <div class="d-flex justify-content-between mb-3">
                <b-form-input
                  v-model="locations.depo.lat"
                  placeholder="Enter Depo Latitude"
                  class="w-50 me-2"
                  required
                ></b-form-input>
                <b-form-input
                  v-model="locations.depo.lng"
                  placeholder="Enter Depo Longitude"
                  class="w-50 ms-2"
                  required
                ></b-form-input>
              </div>
  
              <!-- Dumping Section -->
              <h5 class="mt-3">Dumping</h5>
              <div class="d-flex justify-content-between mb-3">
                <b-form-input
                  v-model="locations.dump.lat"
                  placeholder="Enter Dumping Latitude"
                  class="w-50 me-2"
                  required
                ></b-form-input>
                <b-form-input
                  v-model="locations.dump.lng"
                  placeholder="Enter Dumping Longitude"
                  class="w-50 ms-2"
                  required
                ></b-form-input>
              </div>
  
              <!-- Destination Section -->
              <h5 class="mt-3">Destination</h5>
              <div class="d-flex justify-content-between mb-3">
                <b-form-input
                  v-model="locations.dest.lat"
                  placeholder="Enter Destination Latitude"
                  class="w-50 me-2"
                  required
                ></b-form-input>
                <b-form-input
                  v-model="locations.dest.lng"
                  placeholder="Enter Destination Longitude"
                  class="w-50 ms-2"
                  required
                ></b-form-input>
              </div>
            </div>
  
            <!-- Submit Button -->
            <div>
              <span v-if="permissions.includes('assign-comp-wastetype')">
                <b-button
                  class="float-end"
                  variant="primary"
                  @click="submitRoutingData"
                >
                  Submit
                  <b-spinner
                    v-if="saveloading"
                    class="mt-2"
                    small
                    label="Small Spinner"
                  ></b-spinner>
                </b-button>
              </span>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, onMounted, ref } from "@vue/runtime-core";
  import {
    getCustomersList,
    zones_list,
    addLocation,

  } from "@/store/api/devices";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  export default defineComponent({
    name: "editpermission",
    setup() {
      const items = ref([]);
      const permissions = ref([]);
      const zones = ref([]);
      const waste_type_id = ref("");
      const searchValue = ref();
      const loading = ref(false);
      const saveloading = ref(false);
      const customer_list = ref([]);


      const assign_value = ref({
      company_id: "",
      zone_id: null,
    });
    const locations = ref({
      depo: { lat: "", lng: "" },
      dump: { lat: "", lng: "" },
      dest: { lat: "", lng: "" },
    });
      const deleteIcon = ref(false);
      onMounted(async () => {
        permissions.value = localStorage.getItem("permissions");
        permissions.value = JSON.parse(permissions.value);
        items.value.map((item) => ({ ...item, isEdit: false }));
        const feature = await getCustomersList();
        customer_list.value = feature;
      });
      
   
    // Method to handle zone submission

    const fetchZonesOfCompany = async (event) => {
      loading.value = true;
      const companyId = event;
      if (companyId) {
        // Call the API to get zones
        const result = await zones_list(companyId);
        zones.value = result.data; // Assuming API returns the list of zones
      }
      loading.value = false;
    };

    const submitRoutingData = async () => {
      const payload = {
        se_account_id: assign_value.value.company_id,
        zone_id: assign_value.value.zone_id,
        locations: [
          { name: "Depo", lat: locations.value.depo.lat, lng: locations.value.depo.lng },
          { name: "Dump", lat: locations.value.dump.lat, lng: locations.value.dump.lng },
          { name: "Dest", lat: locations.value.dest.lat, lng: locations.value.dest.lng },
        ],
      };

      try {
        saveloading.value = true;
        await addLocation(payload); // Assuming this is the correct method to call
        saveloading.value = false;
        alert("Location added successfully!");
      } catch (error) {
        saveloading.value = false;
        console.error("Error submitting location:", error);
        alert("Error submitting location data.");
      }
    }

    // Additional handler for selecting a zone if needed
    const onZoneSelect = (zoneId) => {
      console.log("Selected Zone:", zoneId);
      // You can use this selected zone ID for further logic or API calls
    };
      const editRowHandler = async (data) => {
        items.value[data.index].isEdit = !items.value[data.index].isEdit;
      };
      const notEdit = (data) => {
        items.value[data.index].isEdit = false;
      };
  
  
  
      return {
        onZoneSelect,
        editRowHandler,
        notEdit,
        items,
        customer_list,
        deleteIcon,
        waste_type_id,
        loading,
        zones,
        saveloading,
        assign_value,
        searchValue,
        permissions,
        fetchZonesOfCompany,
        submitRoutingData,
        locations
      };
    },
  });
  </script>
  <style scoped>
  .btn.btn-secondary.permission-action {
    padding: 3px !important;
    background: #fff !important;
  }
  .btn.btn-secondary.permission-action:hover {
    background-color: #fff !important;
    background: #fff !important;
  }
  .btn.btn-secondary.permission-action:focus {
    background-color: #fff !important;
    background: #fff !important;
  }
  .badge-light-success.modal-badge {
    padding-top: 4px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
    padding-right: 4px !important;
  }
  /* .table td:last-child {
   display: flex !important;
  } */
  .display_none {
    display: none;
  }
  .display_block {
    display: block;
  }
  span.spinner-border.text-primary {
    z-index: 1;
    position: absolute;
    top: 227px;
    right: 50%;
    justify-content: center;
    display: flex;
  }
  [type="radio"] {
    position: absolute;
    opacity: 0;
  }
  [type="radio"] + img {
    cursor: pointer;
    margin-right: 0.5rem;
  }
  [type="radio"]:checked + img {
    background-color: #0d0c0c;
  }
  .label {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    transition: all 100ms ease-in;
    filter: brightness(0.9) grayscale(1.7) opacity(0.9) !important;
  }
  
  .imgSize {
    margin: 0px;
    width: 37px;
    padding: 0px;
    height: 37px;
  }
  img {
    width: 28px;
    height: 29px;
  }
  .float-end {
    border-color: #009ef7;
  }
  </style>
  