import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"width":"100%","border":"0px"}
}
const _hoisted_2 = {
  class: "card-header border-0",
  style: {"margin":"-13px 0px"}
}
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-2" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2" }
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { style: {"padding-left":"2rem"} }
const _hoisted_13 = { style: {"padding-left":"2rem"} }
const _hoisted_14 = { style: {"padding-left":"2rem"} }
const _hoisted_15 = {
  key: 0,
  href: "#",
  class: "text-gray-600 text-hover-primary mx-1",
  title: "Threshold"
}
const _hoisted_16 = {
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`)
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 1,
  href: "#",
  class: "text-gray-600 text-hover-primary mx-1",
  title: "Edit"
}
const _hoisted_19 = {
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`)
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 2,
  class: "text-gray-600 text-hover-primary mb-1 cursor-pointer",
  title: "Delete"
}
const _hoisted_22 = {
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`)
}
const _hoisted_23 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_RoutingLocation = _resolveComponent("RoutingLocation")!
  const _component_Zone = _resolveComponent("Zone")!
  const _component_AddressDetails = _resolveComponent("AddressDetails")!
  const _component_WasteType = _resolveComponent("WasteType")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_AddCustomer = _resolveComponent("AddCustomer")!
  const _component_UpdateThreshold = _resolveComponent("UpdateThreshold")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-primary mx-10" }, "Companies", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              class: "form-control border mr-3 border-primary form-control-solid w-250px ps-14",
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              placeholder: "search"
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, _ctx.search]
            ])
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", null, [
            _createVNode(_component_RoutingLocation)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_Zone)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_AddressDetails)
          ]),
          (_ctx.permissions.includes('view-wastetype'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_WasteType)
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("begin::Toolbar"),
          _createElementVNode("div", _hoisted_8, [
            _createCommentVNode("begin::Add subscription"),
            _createElementVNode("button", {
              style: {"margin-right":"12px"},
              class: "btn btn-primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.downloadCsv()))
            }, [
              _createCommentVNode(" data-bs-target=\"#kt_modal_new_device\" "),
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _cache[4] || (_cache[4] = _createTextVNode(" Download CSV "))
            ]),
            (_ctx.permissions.includes('company-add'))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn-primary",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#addcustomermodal",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addCustomer()))
                }, [
                  _createCommentVNode(" data-bs-target=\"#kt_modal_new_device\" "),
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
                  ]),
                  _cache[5] || (_cache[5] = _createTextVNode(" Add Company "))
                ]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode("end::Add subscription")
          ]),
          _createCommentVNode("end::Toolbar"),
          _createCommentVNode("begin::Group actions"),
          _cache[6] || (_cache[6] = _createElementVNode("div", {
            class: "d-flex justify-content-end align-items-center d-none",
            "data-kt-subscription-table-toolbar": "selected"
          }, [
            _createElementVNode("div", { class: "fw-bolder me-5" }, [
              _createElementVNode("span", {
                class: "me-2",
                "data-kt-subscription-table-select": "selected_count"
              }),
              _createTextVNode(" Selected ")
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-danger",
              "data-kt-subscription-table-select": "delete_selected"
            }, " Delete Selected ")
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Group actions")
        ]),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.customers,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange
        }, {
          "cell-id": _withCtx(({ row: customer }) => [
            _createElementVNode("div", null, _toDisplayString(customer.id), 1 /* TEXT */)
          ]),
          "cell-name": _withCtx(({ row: customer }) => [
            _createElementVNode("div", null, _toDisplayString(customer.name), 1 /* TEXT */)
          ]),
          "cell-customer_type": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.customer_type), 1 /* TEXT */)
          ]),
          "cell-email": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.email ? customer.email : "N/A"), 1 /* TEXT */)
          ]),
          "cell-phone_number": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.phone_number ? customer.phone_number : "N/A"), 1 /* TEXT */)
          ]),
          "cell-token": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.token), 1 /* TEXT */)
          ]),
          "cell-max_users": _withCtx(({ row: customer }) => [
            _createElementVNode("span", _hoisted_12, _toDisplayString(customer.max_users), 1 /* TEXT */)
          ]),
          "cell-user_count": _withCtx(({ row: customer }) => [
            _createElementVNode("span", _hoisted_13, _toDisplayString(customer.user_count ? customer.user_count : "N/A"), 1 /* TEXT */)
          ]),
          "cell-time_zone": _withCtx(({ row: customer }) => [
            _createElementVNode("span", _hoisted_14, _toDisplayString(customer.time_zone ? customer.time_zone : "N/A"), 1 /* TEXT */)
          ]),
          "cell-action": _withCtx(({ row: customer }) => [
            (_ctx.permissions.includes('update_comp_threshold'))
              ? (_openBlock(), _createElementBlock("a", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("i", {
                      class: "fas fa-recycle text-success",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#thresholdmodal",
                      onClick: ($event: any) => (_ctx.setThreshold(customer))
                    }, null, 8 /* PROPS */, _hoisted_17)
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.permissions.includes('company-update'))
              ? (_openBlock(), _createElementBlock("a", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("i", {
                      class: "fas fa-edit text-success",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#addcustomermodal",
                      onClick: ($event: any) => (_ctx.getCompanyDetail(customer))
                    }, null, 8 /* PROPS */, _hoisted_20)
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.permissions.includes('company-delete'))
              ? (_openBlock(), _createElementBlock("a", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("i", {
                      class: "fas fa-trash text-danger",
                      onClick: ($event: any) => (_ctx.deltCompany(customer.id))
                    }, null, 8 /* PROPS */, _hoisted_23)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createCommentVNode("end::Card"),
    _createCommentVNode(" <EditCompanies :comp=\"obj\"  "),
    _createCommentVNode(" <CustomerTypemodal></CustomerTypemodal> "),
    _createVNode(_component_AddCustomer, { comp: _ctx.obj }, null, 8 /* PROPS */, ["comp"]),
    _createVNode(_component_UpdateThreshold, { customer: _ctx.obj }, null, 8 /* PROPS */, ["customer"])
  ], 64 /* STABLE_FRAGMENT */))
}