import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "addcustomermodal",
  ref: "addcustomermodalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = {
  class: "modal-header bg-primary",
  id: "kt_modal_create_api_key_header"
}
const _hoisted_5 = {
  key: 0,
  class: "modal-title h5 text-uppercase text-white",
  id: "exampleModalLabel",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_6 = {
  key: 1,
  class: "modal-title h5 text-uppercase text-white",
  id: "exampleModalLabel",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_7 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_8 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_9 = { class: "d-flex flex-column mb-10 fv-row" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-md-4" }
const _hoisted_14 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = { class: "col-md-4" }
const _hoisted_18 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_19 = { class: "fv-plugins-message-container" }
const _hoisted_20 = { class: "fv-help-block" }
const _hoisted_21 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_22 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_23 = { class: "fv-plugins-message-container" }
const _hoisted_24 = { class: "fv-help-block" }
const _hoisted_25 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_26 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_27 = { class: "fv-plugins-message-container" }
const _hoisted_28 = { class: "fv-help-block" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-md-4" }
const _hoisted_31 = { class: "mb-5 fv-row" }
const _hoisted_32 = { class: "fv-plugins-message-container" }
const _hoisted_33 = { class: "fv-help-block" }
const _hoisted_34 = { class: "col-md-4" }
const _hoisted_35 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_36 = { class: "fv-plugins-message-container" }
const _hoisted_37 = { class: "fv-help-block" }
const _hoisted_38 = { class: "col-md-4" }
const _hoisted_39 = {
  key: 0,
  class: "mt-9 fv-row"
}
const _hoisted_40 = { class: "row" }
const _hoisted_41 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_42 = { class: "mb-5 fv-row" }
const _hoisted_43 = { class: "fv-plugins-message-container" }
const _hoisted_44 = { class: "fv-help-block" }
const _hoisted_45 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_46 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_47 = { class: "fv-plugins-message-container" }
const _hoisted_48 = { class: "fv-help-block" }
const _hoisted_49 = {
  key: 2,
  class: "col-md-4"
}
const _hoisted_50 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_51 = { class: "fv-plugins-message-container" }
const _hoisted_52 = { class: "fv-help-block" }
const _hoisted_53 = { class: "row" }
const _hoisted_54 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_55 = ["value"]
const _hoisted_56 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_57 = ["value"]
const _hoisted_58 = { class: "fv-plugins-message-container" }
const _hoisted_59 = { class: "fv-help-block" }
const _hoisted_60 = { class: "col-md-4" }
const _hoisted_61 = {
  class: "form-check form-check-sm form-check-custom form-check-solid me-5 fs-5 fw-bold mb-2",
  style: {"margin":"30px"}
}
const _hoisted_62 = ["checked"]
const _hoisted_63 = {
  key: 2,
  class: "col-md-4"
}
const _hoisted_64 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_65 = { class: "row" }
const _hoisted_66 = { class: "col-9" }
const _hoisted_67 = { class: "col-3" }
const _hoisted_68 = { class: "row" }
const _hoisted_69 = { class: "col-md-12" }
const _hoisted_70 = { class: "d-flex flex-column mb-5 mt-5 fv-row" }
const _hoisted_71 = ["value"]
const _hoisted_72 = { class: "fv-plugins-message-container" }
const _hoisted_73 = { class: "fv-help-block" }
const _hoisted_74 = { class: "col-md-12" }
const _hoisted_75 = { class: "groups_list" }
const _hoisted_76 = ["onClick"]
const _hoisted_77 = { class: "group_name" }
const _hoisted_78 = { class: "modal-footer flex-center" }
const _hoisted_79 = ["data-kt-indicator"]
const _hoisted_80 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_81 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Modal - Create Api Key"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Modal header"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin::Modal title"),
            (_ctx.editCustomer?.id)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Update company "))
              : _createCommentVNode("v-if", true),
            (!_ctx.editCustomer?.id)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Add company "))
              : _createCommentVNode("v-if", true),
            _cache[17] || (_cache[17] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "addnewnotificationmodal"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ], -1 /* HOISTED */)),
            _createCommentVNode("end::Close")
          ]),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Form"),
          _createVNode(_component_Form, {
            id: "kt_modal_create_api_key_form",
            class: "form",
            onSubmit: _ctx.submit,
            "validation-schema": _ctx.customer.customer_type == 'external'
            ? _ctx.validationSchemaExternal
            : _ctx.validationSchemaInternal
            
          }, {
            default: _withCtx(() => [
              _createCommentVNode("begin::Modal body"),
              _createElementVNode("div", _hoisted_7, [
                _createCommentVNode("begin::Scroll"),
                _createElementVNode("div", _hoisted_8, [
                  _createCommentVNode("begin::Input group"),
                  _createElementVNode("div", _hoisted_9, [
                    _createCommentVNode("begin::Label"),
                    _cache[19] || (_cache[19] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Select Customer Type", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Select"),
                    _createVNode(_component_Field, {
                      name: "customer_type",
                      "data-control": "select2",
                      "data-hide-search": "true",
                      "data-placeholder": "Select a Category...",
                      class: "form-select form-select-solid border border-primary",
                      as: "select",
                      modelValue: _ctx.customer.customer_type,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.customer_type) = $event))
                    }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createElementVNode("option", { value: "" }, "Select a Category...", -1 /* HOISTED */),
                        _createElementVNode("option", { value: "internal" }, "Internal", -1 /* HOISTED */),
                        _createElementVNode("option", { value: "external" }, "External", -1 /* HOISTED */)
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_ErrorMessage, { name: "customer_type" })
                      ])
                    ]),
                    _createCommentVNode("end::Select")
                  ]),
                  _createCommentVNode("end::Input group"),
                  _createCommentVNode(" Firsr Row "),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_14, [
                        _createCommentVNode("begin::Label"),
                        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Company Name", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "name",
                          placeholder: "Company Name",
                          modelValue: _ctx.customer.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.name) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_ErrorMessage, { name: "name" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_18, [
                        _createCommentVNode("begin::Label"),
                        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Phone Number", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "tel",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "phone_number",
                          modelValue: _ctx.customer.phone_number,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone_number) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_component_ErrorMessage, { name: "phone_number" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    (_ctx.customer.customer_type == 'internal')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_22, [
                            _createCommentVNode("begin::Label"),
                            _cache[22] || (_cache[22] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Maximum User", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "number",
                              class: "form-control form-control-solid border border-primary",
                              rows: "3",
                              modelValue: _ctx.customer.max_users,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.max_users) = $event)),
                              name: "max_users",
                              placeholder: "Maximum User"
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("div", _hoisted_24, [
                                _createVNode(_component_ErrorMessage, { name: "max_users" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.customer.customer_type == 'external')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_26, [
                            _createCommentVNode("begin::Label"),
                            _cache[24] || (_cache[24] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Billing Cycle", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              name: "billing_cycle",
                              "data-control": "select2",
                              "data-hide-search": "true",
                              placeholder: "Billing Cycle",
                              "data-placeholder": "Billing Cycle",
                              class: "form-select border-primary form-select-solid border border-primary",
                              as: "select",
                              modelValue: _ctx.customer.billing_cycle,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.billing_cycle) = $event))
                            }, {
                              default: _withCtx(() => _cache[23] || (_cache[23] = [
                                _createElementVNode("option", { value: "one_month" }, "One Month", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "three_month" }, "Three Months", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "six_month" }, "Six Months", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "one_year" }, "One Year", -1 /* HOISTED */)
                              ])),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("div", _hoisted_28, [
                                _createVNode(_component_ErrorMessage, { name: "billing_cycle" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _createCommentVNode(" First Row end "),
                  _createCommentVNode(" Second row "),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_31, [
                        _createCommentVNode("begin::Label"),
                        _cache[25] || (_cache[25] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, " Email Address", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "email",
                          class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                          placeholder: "Email...",
                          name: "email",
                          modelValue: _ctx.customer.email,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.email) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            _createVNode(_component_ErrorMessage, { name: "email" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_35, [
                        _createCommentVNode("begin::Label"),
                        _cache[26] || (_cache[26] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Token", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          placeholder: "Token",
                          name: "token",
                          modelValue: _ctx.customer.token,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.token) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, [
                            _createVNode(_component_ErrorMessage, { name: "token" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _createCommentVNode("begin::Input group"),
                      (_ctx.customer.customer_type == 'internal' ||
            _ctx.customer.customer_type == 'external'
            )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-primary",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getRandomString()))
                            }, " Generate Token ")
                          ]))
                        : _createCommentVNode("v-if", true),
                      _createCommentVNode("end::Input group")
                    ])
                  ]),
                  _createCommentVNode(" Second row end "),
                  _createCommentVNode(" Third row "),
                  _createElementVNode("div", _hoisted_40, [
                    (_ctx.customer.customer_type == 'external')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_42, [
                            _createCommentVNode("begin::Label"),
                            _cache[27] || (_cache[27] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Vat Number", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                              placeholder: "Vat Number",
                              name: "vat_number",
                              modelValue: _ctx.customer.vat_number,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customer.vat_number) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("div", _hoisted_44, [
                                _createVNode(_component_ErrorMessage, { name: "vat_number" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.customer.customer_type == 'external')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_46, [
                            _createCommentVNode("begin::Label"),
                            _cache[28] || (_cache[28] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Delivery Address", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control form-control-solid border border-primary",
                              rows: "3",
                              name: "delivery_address",
                              placeholder: "Deleviry Address",
                              modelValue: _ctx.customer.delivery_address,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customer.delivery_address) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_47, [
                              _createElementVNode("div", _hoisted_48, [
                                _createVNode(_component_ErrorMessage, { name: "delivery_address" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.customer.customer_type == 'external')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_50, [
                            _createCommentVNode("begin::Label"),
                            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Billing Address", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control form-control-solid border border-primary",
                              rows: "3",
                              name: "billing_address",
                              placeholder: "Billing Address",
                              modelValue: _ctx.customer.billing_address,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.customer.billing_address) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_51, [
                              _createElementVNode("div", _hoisted_52, [
                                _createVNode(_component_ErrorMessage, { name: "billing_address" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _createCommentVNode(" Third row end "),
                  _createCommentVNode(" Fourth Row "),
                  _createElementVNode("div", _hoisted_53, [
                    _createCommentVNode("Start Parent List Field  "),
                    (_ctx.customer.customer_type == 'internal' ||
            _ctx.customer.customer_type == 'external'
            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                          _createCommentVNode("begin::Input group"),
                          _createCommentVNode("begin::Label"),
                          _cache[31] || (_cache[31] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Parent", -1 /* HOISTED */)),
                          _createCommentVNode("end::Label"),
                          _createCommentVNode("begin::Select"),
                          _createVNode(_component_Field, {
                            name: "parent_id",
                            "data-control": "select2",
                            "data-hide-search": "true",
                            "data-placeholder": "Select Parent",
                            class: "form-select border border-primary form-select-solid",
                            as: "select",
                            modelValue: _ctx.customer.parent_id,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.customer.parent_id) = $event))
                          }, {
                            default: _withCtx(() => [
                              _cache[30] || (_cache[30] = _createElementVNode("option", { value: "0" }, "Select Company", -1 /* HOISTED */)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parentList, (parent, i) => {
                                return (_openBlock(), _createElementBlock("option", {
                                  key: i,
                                  value: `${parent.id}`
                                }, _toDisplayString(parent.name +
            " (ID: " +
            parent.id +
            " , Service_id: " +
            parent.service_id +
            ")"), 9 /* TEXT, PROPS */, _hoisted_55))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["modelValue"]),
                          _createCommentVNode("end::Select"),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createCommentVNode(" End Parent List Field "),
                    (_ctx.customer.customer_type == 'internal' ||
            _ctx.customer.customer_type == 'external'
            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                          _createCommentVNode("begin::Input group"),
                          _createCommentVNode("begin::Label"),
                          _cache[33] || (_cache[33] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Package", -1 /* HOISTED */)),
                          _createCommentVNode("end::Label"),
                          _createCommentVNode("begin::Select"),
                          _createVNode(_component_Field, {
                            name: "service_id",
                            "data-control": "select2",
                            "data-hide-search": "true",
                            "data-placeholder": "Select Package",
                            class: "form-select border border-primary form-select-solid",
                            as: "select",
                            modelValue: _ctx.customer.service_id,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.customer.service_id) = $event))
                          }, {
                            default: _withCtx(() => [
                              _cache[32] || (_cache[32] = _createElementVNode("option", { value: "" }, "Select Package", -1 /* HOISTED */)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service, i) => {
                                return (_openBlock(), _createElementBlock("option", {
                                  key: i,
                                  value: `${service.id}`
                                }, _toDisplayString(service.name), 9 /* TEXT, PROPS */, _hoisted_57))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["modelValue"]),
                          _createElementVNode("div", _hoisted_58, [
                            _createElementVNode("div", _hoisted_59, [
                              _createVNode(_component_ErrorMessage, { name: "service_id" })
                            ])
                          ]),
                          _createCommentVNode("end::Select"),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_60, [
                      _createElementVNode("label", _hoisted_61, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          class: "form-check-input",
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.customer.is_kpi) = $event)),
                          checked: _ctx.customer.is_kpi
                        }, null, 8 /* PROPS */, _hoisted_62), [
                          [_vModelCheckbox, _ctx.customer.is_kpi]
                        ]),
                        _cache[34] || (_cache[34] = _createElementVNode("span", { class: "form-check-label" }, " Enable KPI Dashboard", -1 /* HOISTED */))
                      ])
                    ]),
                    (_ctx.customer.is_kpi && !_ctx.editCustomer?.id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_64, [
                            _createCommentVNode("begin::Label"),
                            _cache[35] || (_cache[35] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Groups Name", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createElementVNode("div", _hoisted_65, [
                              _createElementVNode("div", _hoisted_66, [
                                _createCommentVNode("begin::Input"),
                                _createVNode(_component_Field, {
                                  type: "text",
                                  class: "form-control form-control-solid border border-primary",
                                  rows: "3",
                                  name: "group_name",
                                  placeholder: "Groups Name",
                                  modelValue: _ctx.groupName,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.groupName) = $event))
                                }, null, 8 /* PROPS */, ["modelValue"]),
                                _createCommentVNode("end::Input")
                              ]),
                              _createElementVNode("div", _hoisted_67, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn add-group",
                                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.addGroup()))
                                }, "Go")
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _createCommentVNode(" Fourth row end "),
                  _createCommentVNode(" Fifth Row"),
                  _createElementVNode("div", _hoisted_68, [
                    _createElementVNode("div", _hoisted_69, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_70, [
                        _createCommentVNode("begin::Label"),
                        _cache[36] || (_cache[36] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Time Zone", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "time_zone",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "Select a TimeZone...",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.customer.time_zone,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customer.time_zone) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timezones, (timezone) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: timezone,
                                value: timezone
                              }, _toDisplayString(timezone), 9 /* TEXT, PROPS */, _hoisted_71))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_72, [
                          _createElementVNode("div", _hoisted_73, [
                            _createVNode(_component_ErrorMessage, { name: "time_zone" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_74, [
                      _createCommentVNode(" List of group names "),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer.group_name, (group, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "row",
                          key: index
                        }, [
                          _createElementVNode("p", _hoisted_75, [
                            _createElementVNode("span", {
                              class: "group_delete",
                              onClick: ($event: any) => (_ctx.deleteGroup(index)),
                              style: {"color":"#A0240A"}
                            }, "✖", 8 /* PROPS */, _hoisted_76),
                            _createElementVNode("span", _hoisted_77, _toDisplayString(group), 1 /* TEXT */)
                          ])
                        ]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]),
                  _createCommentVNode(" End Fifth Row")
                ]),
                _createCommentVNode("end::Scroll")
              ]),
              _createCommentVNode("end::Modal body"),
              _createCommentVNode("begin::Modal footer"),
              _createElementVNode("div", _hoisted_78, [
                _createCommentVNode("begin::Button"),
                _cache[38] || (_cache[38] = _createElementVNode("button", {
                  type: "reset",
                  id: "kt_modal_create_api_key_cancel",
                  class: "btn btn-white me-3"
                }, " Clear ", -1 /* HOISTED */)),
                _createCommentVNode("end::Button"),
                _createCommentVNode("begin::Button"),
                _createElementVNode("button", {
                  ref: "submitButtonRef",
                  type: "submit",
                  id: "kt_modal_create_api_key_submit",
                  class: "btn btn-primary",
                  "data-kt-indicator": _ctx.loading ? 'on' : null
                }, [
                  (!_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_80, " Submit"))
                    : _createCommentVNode("v-if", true),
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_81, _cache[37] || (_cache[37] = [
                        _createTextVNode(" Please wait... "),
                        _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1 /* HOISTED */)
                      ])))
                    : _createCommentVNode("v-if", true)
                ], 8 /* PROPS */, _hoisted_79),
                _createCommentVNode("end::Button")
              ]),
              _createCommentVNode("end::Modal footer")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
          _createCommentVNode("end::Form")
        ]),
        _createCommentVNode("end::Modal content")
      ]),
      _createCommentVNode("end::Modal dialog")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end::Modal - Create Api Key")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}